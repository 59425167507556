import {
  Stack,
  StackProps,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Container } from 'components/Container'
import { Icon } from 'components/Icon'
// import { ToggleTheme } from 'components/ToggleTheme'
import { BASE_URL_IMAGES } from 'constants/baseUrlImages'
import { useMenu } from 'hooks/useMenu'
import { useAuthStore } from 'store/auth.store'

import { DefaultMenu } from '../DefaultMenu'
import { MyAccount } from '../MyAccount'
import { FormMyAccount } from '../MyAccount/FormMyAccount'
import { Navbar } from '../Navbar'
import { HeaderOptions } from './HeaderOptions'

type Props = StackProps & {
  marginBottom?: number
  paddingLeft?: number
}

export function Header(props: Props) {
  const { t } = useTranslation('common')
  const { menus, activeMenu } = useMenu()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { user, logout } = useAuthStore((state) => state)
  const [openForm, setOpenForm] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseForm = () => {
    setOpenForm(false)
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    queryClient.clear()

    navigate('/login')
  }

  return (
    <Stack
      component='header'
      direction='row'
      alignItems='center'
      sx={{
        minHeight: 104,
        borderBottom: (theme) => `2px solid ${theme.palette.secondary.light}`,
        marginBottom: props?.marginBottom ?? 3,
        paddingLeft: props?.paddingLeft ?? 0,
        ...props?.sx,
      }}
    >
      <Container>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={props?.sx}
        >
          <Stack direction='row' alignItems='center' spacing={2}>
            {!isDesktop && <DefaultMenu options={menus} />}

            <Navbar menu={activeMenu} />
          </Stack>

          <HeaderOptions>
            {/* <ToggleTheme /> */}

            <MyAccount.Root>
              {/* <MyAccount.Modal.Button
                title={t('button.title.myAccount')}
                onClick={handleClick}
              /> */}
              <Tooltip title='Minha conta'>
                <div>
                  <MyAccount.Item.Avatar
                    src={
                      user?.avatar
                        ? `${BASE_URL_IMAGES}${user?.avatar}`
                            ?.replaceAll('//', '/')
                            ?.replaceAll('http:/', 'http://')
                            ?.replaceAll('https:/', 'https://')
                        : undefined
                    }
                    onClick={handleClick}
                    sx={{
                      cursor: 'pointer',
                      borderRadius: 1,
                    }}
                  />
                </div>
              </Tooltip>

              <MyAccount.Modal.Root
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MyAccount.Content>
                  <MyAccount.Item.Root>
                    <MyAccount.Item.Avatar
                      src={
                        user?.avatar
                          ? `${BASE_URL_IMAGES}${user?.avatar}`
                              ?.replaceAll('//', '/')
                              ?.replaceAll('http:/', 'http://')
                              ?.replaceAll('https:/', 'https://')
                          : undefined
                      }
                    />
                    <MyAccount.Item.Content
                      name={user?.name}
                      email={user?.email}
                    />
                  </MyAccount.Item.Root>

                  <MyAccount.Actions>
                    <MyAccount.Action
                      title={t('button.title.editAccount')}
                      variant='contained'
                      onClick={() => setOpenForm(true)}
                    />
                    <MyAccount.Action
                      title={t('button.title.logout')}
                      icon={<Icon name='logout' />}
                      onClick={handleLogout}
                    />
                  </MyAccount.Actions>
                </MyAccount.Content>
              </MyAccount.Modal.Root>

              {openForm && (
                <FormMyAccount open={openForm} onClose={handleCloseForm} />
              )}
            </MyAccount.Root>
          </HeaderOptions>
        </Stack>
      </Container>
    </Stack>
  )
}
