import { httpClient } from 'services/api/httpClient'

type LoginType = {
  login: string
  password: string
}

const login = (data: LoginType) => {
  return httpClient.post('/auth/login', {
    login: data.login,
    password: data.password,
  })
}

const refreshToken = (token: string) => {
  return httpClient.post('/auth/refresh-token', {
    token,
  })
}

const getUser = () => {
  // return httpClient.get('/auth/me')
  return httpClient.get('auth/profile')
}

export const authService = {
  login,
  refreshToken,
  getUser,
}
