import { RouteProps } from 'react-router-dom'

import { GardPage } from 'pages/guard/GuardPage'
import { useAuthStore } from 'store/auth.store'

type RouterGuardProps = RouteProps & {
  module?: string
  component: React.ComponentType
}

const RouterGuard = ({
  module,
  component: Component,
}: RouterGuardProps): React.ReactElement | null => {
  const { permissions, user } = useAuthStore()

  if (!permissions) return <GardPage />

  if (user?.role?.slug?.toLowerCase()?.includes('administrador') || !module) {
    return <Component />
  }

  const hasPermission = permissions.includes(module)

  if (!hasPermission) return <GardPage />

  return hasPermission ? <Component /> : <GardPage />
}

export default RouterGuard
