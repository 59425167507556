import { FormMyAccountType, MyAccountType } from 'types/my-account/myAccount'

import { httpClient } from './api/httpClient'

type ResponseType = MyAccountType & {
  id: number
}

export const get = async (): Promise<ResponseType> => {
  const { data } = await httpClient.get('auth/profile')

  return data
}

export const update = ({ data }: { data: FormMyAccountType }) => {
  return httpClient.patch('/auth/profile', data)
}

export const myAccountService = {
  get,
  update,
}
