import { lazy } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import { DefaultLayout } from 'layouts/DefaultLayout'
import { PublicLayout } from 'layouts/PublicLayout'
import { CreatePasswordPage } from 'pages/auth/CreatePasswordPage'
import { FirstAccessPage } from 'pages/auth/FirstAccessPage'
import { ForgotPasswordPage } from 'pages/auth/ForgotPasswordPage'
import { FirstAccessEmailPage } from 'pages/auth/mail/FirstAccessEmailPage'
import { ResetPasswordEmailPage } from 'pages/auth/mail/ResetPasswordEmailPage'
import { ResetPasswordPage } from 'pages/auth/ResetPasswordPage'
import { SignInPage } from 'pages/auth/SignInPage'
import ErrorPage from 'pages/error/ErrorPage'

import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import RouterGuard from './RouterGuard'

const MessageBoardPage = lazy(
  () => import('pages/messageBoard/MessageBoardPage'),
)
const UserDetailsFormPage = lazy(() => import('pages/user/UserDetailsFormPage'))
const UserFormPage = lazy(() => import('pages/user/UserFormPage'))
const UserPage = lazy(() => import('pages/user/UserPage'))
const FilePage = lazy(() => import('pages/file/FilePage'))
const DocumentPage = lazy(() => import('pages/document/DocumentPage'))
const DocumentTypePage = lazy(
  () => import('pages/documentType/DocumentTypePage'),
)
const PolePage = lazy(() => import('pages/pole/PolePage'))
const FormOfWorkPage = lazy(() => import('pages/formOfWork/FormOfWorkPage'))
const WorkingRegimePage = lazy(
  () => import('pages/workingRegime/WorkingRegimePage'),
)
const DashboardPage = lazy(() => import('pages/dashboard/DashboardPage'))
const VacationPage = lazy(() => import('pages/vacation/VacationPage'))
const VacationFormPage = lazy(() => import('pages/vacation/VacationFormPage'))
const ProfilePage = lazy(() => import('pages/profile/ProfilePage'))

export const routers = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* ------------------ PRIVATE PAGES ------------------ */}
      <Route
        path='/'
        element={<ProtectedRoute layout={DefaultLayout} />}
        // loader={rootLoader}
        // action={rootAction}
        errorElement={<ErrorPage />}
      >
        <Route>
          <Route index element={<DashboardPage />} />
          <Route path='dashboard' element={<DashboardPage />} />

          <Route
            path='arquivos'
            element={<RouterGuard module='file' component={FilePage} />}
          />
          <Route
            path='polos'
            element={<RouterGuard module='polo' component={PolePage} />}
          />
          <Route
            path='regime-de-trabalho'
            element={
              <RouterGuard
                module='working-regime'
                component={WorkingRegimePage}
              />
            }
          />
          <Route
            path='forma-de-trabalho'
            element={
              <RouterGuard module='form-of-work' component={FormOfWorkPage} />
            }
          />
          <Route
            path='tipos-de-documentos'
            element={<RouterGuard module='document' component={DocumentPage} />}
          />
          <Route
            path='subtipos-de-documentos'
            element={
              <RouterGuard
                module='document-type'
                component={DocumentTypePage}
              />
            }
          />
          <Route
            path='colaboradores'
            element={<RouterGuard module='user' component={UserPage} />}
          />
          <Route
            path='colaboradores/formulario'
            element={<RouterGuard module='user' component={UserFormPage} />}
          />
          <Route
            path='colaboradores/info'
            element={
              <RouterGuard module='user' component={UserDetailsFormPage} />
            }
          />
          <Route
            path='mural-de-recados'
            element={<RouterGuard component={MessageBoardPage} />}
          />
          <Route
            path='ferias'
            element={<RouterGuard module='vacation' component={VacationPage} />}
          />
          <Route
            path='ferias/solicitar-ferias'
            element={
              <RouterGuard module='vacation' component={VacationFormPage} />
            }
          />
          <Route
            path='perfis'
            element={<RouterGuard module='role' component={ProfilePage} />}
          />
        </Route>
      </Route>

      {/* ------------------ PUBLIC PAGES ------------------ */}
      <Route
        path='/'
        element={<PublicRoute layout={PublicLayout} />}
        // loader={rootLoader}
        // action={rootAction}
        errorElement={<ErrorPage />}
      >
        <Route>
          <Route path='login' element={<SignInPage />} />
          <Route path='forgot-password' element={<ForgotPasswordPage />} />
          <Route
            path='reset-password/:token?'
            element={<ResetPasswordPage />}
          />
          <Route path='create-password' element={<CreatePasswordPage />} />
          <Route path='first-access' element={<FirstAccessPage />} />
          <Route path='email-first-access' element={<FirstAccessEmailPage />} />
          <Route
            path='email-reset-password'
            element={<ResetPasswordEmailPage />}
          />
        </Route>
      </Route>
    </>,
  ),
)
