import { useMediaQuery, Drawer, Stack, useTheme } from '@mui/material'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Button/IconButton'

interface Props {
  children: ReactNode
}

export function HeaderOptions({ children }: Props) {
  const { t } = useTranslation('common')
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [open, setOpen] = useState(false)

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpen(open)
    }

  return isDesktop ? (
    <>
      <Stack
        direction={'row'}
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        {children}
      </Stack>
    </>
  ) : (
    <>
      <IconButton
        variant='outlined'
        title={t('menu.title') ?? ''}
        iconName='tune'
        onClick={() => setOpen(true)}
      />

      <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
        <Stack alignItems={'flex-end'} paddingTop={2} paddingRight={2}>
          <IconButton
            variant='outlined'
            title={t('button.title.close') ?? ''}
            iconName='close'
            onClick={toggleDrawer(false)}
          />
        </Stack>
        <Stack spacing={2} padding={2}>
          {children}
        </Stack>
      </Drawer>
    </>
  )
}
