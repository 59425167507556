import { create } from 'zustand'

import { httpClient } from 'services/api/httpClient'
import { UserType } from 'types/user/userType'

type AuthState = {
  user: UserType | null
  isAuthenticated: boolean
  token: string | null
  login: (token: string) => void
  logout: () => void
  setUser: (user: UserType) => void
  permissions: string[]
  setPermissions: (permissions: string[]) => void
}

const getUser = localStorage.getItem('application.auth.user')
const getPermission = localStorage.getItem('application.auth.permission')
const getToken = localStorage.getItem('application.auth.token')

export const useAuthStore = create<AuthState>((set) => ({
  user: getUser ? JSON.parse(getUser) : null,
  isAuthenticated: !!getUser && !!getToken,
  token: getUser,
  login: (token: string) => {
    localStorage.setItem('application.auth.token', token)
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

    set({ token, isAuthenticated: true })
  },
  logout: () => {
    localStorage.removeItem('application.auth.token')
    localStorage.removeItem('application.auth.user')
    localStorage.removeItem('application.auth.permission')
    httpClient.defaults.headers.common['Authorization'] = null

    set({ user: null, token: null, isAuthenticated: false, permissions: [] })
  },
  setUser: (user: UserType) => {
    localStorage.setItem('application.auth.user', JSON.stringify(user))
    const permissions = user.role.RolePermission.map(
      (rolePermission) => rolePermission?.permission?.slug,
    )
    localStorage.setItem(
      'application.auth.permission',
      JSON.stringify(permissions),
    )

    set({ user, permissions })
  },
  permissions: getPermission ? JSON.parse(getPermission) : [],
  setPermissions: (permissions: string[]) => {
    localStorage.setItem(
      'application.auth.permission',
      JSON.stringify(permissions),
    )

    set({ permissions })
  },
}))

export const logout = useAuthStore.getState().logout
