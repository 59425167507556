import { Box, Stack, Typography } from '@mui/material'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Logo } from 'components/Logo'

import { useFirstAccess } from './hooks/useFirstAccess'

export function FirstAccessPage() {
  const { control, errors, handleSubmit, onSubmit, t, isSubmitting } =
    useFirstAccess()

  return (
    <Stack
      width='100%'
      direction={'row'}
      justifyContent={['center', 'space-around']}
      alignItems={'center'}
    >
      <Box
        sx={{
          display: ['none', 'flex'],
          minWidth: 350,
          minHeight: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo mode='HORIZONTAL' />
      </Box>

      <Stack
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        spacing={5}
        sx={{
          padding: [6],
          border: (theme) => [
            'none',
            `1px solid ${theme.palette.secondary.light}`,
          ],
          background: (theme) => [
            'transparent',
            theme.palette.background.default,
          ],
          borderRadius: 1,
          width: ['100%', 500],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack spacing={5} width='100%' alignItems={'flex-start'}>
          <Stack spacing={1}>
            <Typography variant='h1'>{t('firstAccess')}</Typography>
            <Typography variant='body2'>{t('createLogin')}</Typography>
          </Stack>

          <Stack spacing={3} width='100%'>
            <Input
              name='login'
              label={t('input.login')}
              control={control}
              error={!!errors?.login}
              errorMessage={errors?.login?.message}
              required
            />
            <Input
              name='accessKey'
              label={t('input.accessKey')}
              control={control}
              error={!!errors?.accessKey}
              errorMessage={errors?.accessKey?.message}
              required
            />
          </Stack>

          <Button
            variant='contained'
            fullWidth
            type='submit'
            disabled={isSubmitting}
          >
            {t('button.title.send')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
