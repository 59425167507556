import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'

import { ENVIRONMENT } from 'constants/environment'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { logout } from 'store/auth.store'

const endpoint = ENVIRONMENT.API_URL

export function configApi() {
  const getToken = localStorage.getItem('application.auth.token')

  const api = axios.create({
    baseURL: endpoint,
    headers: {
      // Authorization: `Bearer ${getToken}`,
    },
  })

  if (getToken)
    api.defaults.headers.common['Authorization'] = `Bearer ${getToken}`

  api.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        const { code, message } = error?.response?.data as {
          code: string
          message: string
        }
        if (code === 'Unauthorized' || message === 'Unauthorized') {
          toast.warn('Your session has expired. sign in again!')
          logout()
        } else {
          getErrorMessage(error)
          logout()
        }
      }

      if (error.response?.status === 500) {
        toast.error('Server Error')
        throw new Error('Server Error')
      }

      if (error.response?.status === 429) {
        const { message } = error?.response?.data as {
          code: string
          message: string
        }
        toast.warn(
          'Muitas requisições por minuto, aguarde e tente novamente mais tarde',
        )
        throw new Error(message)
      }

      return Promise.reject(error)
    },
  )

  return api
}
