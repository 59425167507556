import {
  AccessTime,
  Add,
  Brightness7,
  Check,
  ChevronLeft,
  ChevronRight,
  Close,
  DarkMode,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  Help,
  Menu,
  RemoveOutlined,
  MoreHoriz,
  MoreVert,
  Notifications,
  NotificationsNone,
  PersonOutline,
  PhotoOutlined,
  PictureAsPdf,
  Remove,
  SettingsOutlined,
  TuneOutlined,
  LockOutlined,
  LockOpenOutlined,
  ArrowBack,
  ErrorOutline,
  Cached,
  GridView,
  RadioButtonUnchecked,
  Timelapse,
  WarningAmber,
  HourglassEmpty,
  Logout,
  Star,
  StarOutline,
  CameraAltOutlined,
} from '@mui/icons-material'

import { ReactComponent as WaterSvg } from '../../assets/icons/agua.svg'
import { ReactComponent as DeleteSvg } from '../../assets/icons/delete.svg'
import { ReactComponent as DownloadSvg } from '../../assets/icons/download.svg'
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg'
import { ReactComponent as EnergySvg } from '../../assets/icons/energia.svg'
import { ReactComponent as ExcelSvg } from '../../assets/icons/excel.svg'
import { ReactComponent as GasSvg } from '../../assets/icons/gas.svg'
import { ReactComponent as HiddenSvg } from '../../assets/icons/hidden.svg'
import { ReactComponent as PresentationSvg } from '../../assets/icons/presentation.svg'
import { ReactComponent as TemperatureSvg } from '../../assets/icons/temperaturaIcon.svg'
import { ReactComponent as TemperatureHumiditySvg } from '../../assets/icons/temperaturaUmidade.svg'
import { ReactComponent as HumiditySvg } from '../../assets/icons/umidade.svg'
import { ReactComponent as VisibilitySvg } from '../../assets/icons/visibility.svg'
import { IconSvg } from '../SvgIcon'

const icons = {
  check: <Check fontSize='small' />,
  chevronLeft: <ChevronLeft fontSize='small' />,
  chevronRight: <ChevronRight fontSize='small' />,
  expandLess: <ExpandLessOutlined fontSize='small' />,
  expandMore: <ExpandMoreOutlined fontSize='small' />,
  water: <IconSvg component={WaterSvg} fontSize='small' />,
  gas: <IconSvg component={GasSvg} fontSize='small' />,
  energy: <IconSvg component={EnergySvg} fontSize='small' />,
  // eslint-disable-next-line camelcase
  temperature_humidity: (
    <IconSvg component={TemperatureHumiditySvg} fontSize='small' />
  ),
  humidity: <IconSvg component={HumiditySvg} fontSize='small' />,
  presentation: <IconSvg component={PresentationSvg} fontSize='small' />,
  hidden: <IconSvg component={HiddenSvg} fontSize='small' />,
  show: <IconSvg component={VisibilitySvg} fontSize='small' />,
  add: <Add fontSize='small' />,
  edit: <IconSvg component={EditSvg} fontSize='small' />,
  delete: <IconSvg component={DeleteSvg} fontSize='small' />,
  pdf: <PictureAsPdf fontSize='small' />,
  excel: <IconSvg component={ExcelSvg} fontSize='small' />,
  download: <IconSvg component={DownloadSvg} fontSize='small' />,
  temperature: <IconSvg component={TemperatureSvg} fontSize='small' />,
  darkMode: <DarkMode fontSize='small' />,
  brightness7: <Brightness7 fontSize='small' />,
  moreVert: <MoreVert fontSize='small' />,
  moreHoriz: <MoreHoriz fontSize='small' />,
  notificationsNone: <NotificationsNone fontSize='small' />,
  notifications: <Notifications fontSize='small' />,
  close: <Close fontSize='small' />,
  personOutline: <PersonOutline fontSize='small' />,
  menu: <Menu fontSize='small' />,
  help: <Help fontSize='small' />,
  remove: <Remove fontSize='small' />,
  photo: <PhotoOutlined fontSize='small' />,
  settings: <SettingsOutlined fontSize='small' />,
  tune: <TuneOutlined fontSize='small' />,
  time: <AccessTime fontSize='small' />,
  historic: <AccessTime fontSize='small' />,
  zoomOut: <RemoveOutlined fontSize='small' />,
  zoomIn: <Add fontSize='small' />,
  lock: <LockOutlined fontSize='small' />,
  unlock: <LockOpenOutlined fontSize='small' />,
  goBack: <ArrowBack fontSize='small' />,
  error: <ErrorOutline fontSize='small' />,
  refresh: <Cached fontSize='small' />,
  grid: <GridView fontSize='small' />,
  circle: <RadioButtonUnchecked fontSize='small' />,
  timelapse: <Timelapse fontSize='small' />,
  warning: <WarningAmber fontSize='small' />,
  followOS: <HourglassEmpty fontSize='small' />,
  logout: <Logout fontSize='small' />,
  star: <Star fontSize='small' />,
  starOutline: <StarOutline fontSize='small' />,
  camera: <CameraAltOutlined fontSize='small' />,
}

export type IconNamesProps =
  | 'check'
  | 'chevronLeft'
  | 'show'
  | 'hidden'
  | 'add'
  | 'chevronRight'
  | 'edit'
  | 'delete'
  | 'pdf'
  | 'excel'
  | 'download'
  | 'brightness7'
  | 'darkMode'
  | 'moreVert'
  | 'moreHoriz'
  | 'notificationsNone'
  | 'notifications'
  | 'close'
  | 'personOutline'
  | 'menu'
  | 'help'
  | 'remove'
  | 'photo'
  | 'settings'
  | 'tune'
  | 'temperature'
  | 'time'
  | 'historic'
  | 'expandLess'
  | 'expandMore'
  | 'zoomOut'
  | 'zoomIn'
  | 'lock'
  | 'unlock'
  | 'goBack'
  | 'error'
  | 'refresh'
  | 'grid'
  | 'presentation'
  | 'temperature_humidity'
  | 'water'
  | 'gas'
  | 'energy'
  | 'humidity'
  | 'circle'
  | 'timelapse'
  | 'warning'
  | 'followOS'
  | 'logout'
  | 'star'
  | 'starOutline'
  | 'camera'

type Props = {
  name: IconNamesProps
}

export function Icon({ name }: Props) {
  return icons[name]
}
