import { Link, Stack } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Logo } from 'components/Logo'

import { useSignIn } from './hooks/useSignIn'

export function SignInPage() {
  const { control, errors, handleSubmit, onSubmit, t, isSubmitting } =
    useSignIn()
  return (
    <Stack
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      spacing={5}
      sx={{
        padding: [6],
        border: (theme) => [
          'none',
          `1px solid ${theme.palette.secondary.light}`,
        ],
        background: (theme) => [
          'transparent',
          theme.palette.background.default,
        ],
        borderRadius: 1,
        width: ['100%', 500],
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Logo mode='VERTICAL' />

      <Stack spacing={3} width='100%'>
        <Input
          name='username'
          type='email'
          label={'Email'}
          control={control}
          error={!!errors?.username}
          errorMessage={errors?.username?.message}
          required
        />
        <Input
          name='password'
          type='password'
          label={t('input.password')}
          control={control}
          error={!!errors?.password}
          errorMessage={errors?.password?.message}
          required
        />
      </Stack>

      <Button
        variant='contained'
        fullWidth
        type='submit'
        disabled={isSubmitting}
      >
        {t('button.title.login')}
      </Button>

      <Link
        component={RouterLink}
        to='/forgot-password'
        color='inherit'
        variant='body2'
        underline='none'
        fontSize='0.875rem'
        fontStyle='italic'
        fontWeight={300}
        sx={{
          transition: 'all 0.3s',
          '&:hover': {
            opacity: 0.5,
          },
        }}
      >
        {t('forgotPassword')}
      </Link>
    </Stack>
  )
}
