import { Check } from '@mui/icons-material'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import PasswordStrengthBar from 'components/Input/PasswordStrengthBar'
import { Logo } from 'components/Logo'

import { useResetPassword } from './hooks/useResetPassword'

export function ResetPasswordPage() {
  const {
    control,
    errors,
    handleSubmit,
    onSubmit,
    t,
    calculatePasswordStrength,
    isSubmitting,
    navigate,
    registrationDone,
  } = useResetPassword()

  return (
    <Stack
      width='100%'
      direction={'row'}
      justifyContent={['center', 'space-around']}
      alignItems={'center'}
    >
      <Box
        sx={{
          display: ['none', 'flex'],
          minWidth: 350,
          minHeight: 400,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo mode='HORIZONTAL' />
      </Box>

      <Stack
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        spacing={5}
        sx={{
          padding: [6],
          border: (theme) => [
            'none',
            `1px solid ${theme.palette.secondary.light}`,
          ],
          background: (theme) => [
            'transparent',
            theme.palette.background.default,
          ],
          borderRadius: 1,
          width: ['100%', 500],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!registrationDone ? (
          <Stack spacing={5} width='100%' alignItems={'flex-start'}>
            <Stack spacing={1}>
              <Typography variant='h1'>{t('createPassword')}</Typography>
            </Stack>

            <Stack spacing={3} width='100%'>
              <Stack spacing={1}>
                <Input
                  name='password'
                  label={t('input.password')}
                  type='password'
                  control={control}
                  error={!!errors?.password}
                  errorMessage={errors?.password?.message}
                  required
                />
                <PasswordStrengthBar value={calculatePasswordStrength()} />
              </Stack>

              <Input
                name='passwordConfirmation'
                label={t('input.repeatPassword')}
                type='password'
                control={control}
                error={!!errors?.passwordConfirmation}
                errorMessage={errors?.passwordConfirmation?.message}
                required
              />
            </Stack>

            <Button
              variant='contained'
              fullWidth
              type='submit'
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={18} color='secondary' />
              ) : (
                t('button.title.createPassword')
              )}
            </Button>
          </Stack>
        ) : (
          <Stack
            spacing={5}
            width='100%'
            alignItems={'center'}
            textAlign={'center'}
          >
            <Stack
              sx={{
                height: 44,
                width: 44,
                borderRadius: 22,
                background: (theme) => theme.palette.primary.main,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Check fontSize='large' />
            </Stack>

            <Stack>
              <Typography variant='h1'>{t('registrationDone')}</Typography>
              <Typography variant='body2'>
                {t('registrationDoneDescription')}
              </Typography>
            </Stack>

            <Button
              variant='contained'
              fullWidth
              onClick={() => navigate('/login')}
              sx={{
                textTransform: 'initial',
              }}
            >
              {t('button.title.login')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
