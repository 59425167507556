/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'

export function useForgotPassword() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [emailSent, setEmailSent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit: SubmitHandler<{ email: string }> = (formData) => {
    setIsSubmitting(true)
    httpClient
      .post('/auth/forgot-password', formData)
      .then(() => {
        setEmailSent(true)

        setIsSubmitting(false)
      })
      .catch((error) => {
        setIsSubmitting(false)
        getErrorMessage(error)
      })
  }

  return {
    control,
    handleSubmit,
    onSubmit,
    errors,
    t,
    navigate,
    emailSent,
    isSubmitting,
  }
}
