import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export function useFirstAccess() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ login: string; accessKey: string }>({
    defaultValues: {
      login: '',
      accessKey: '',
    },
  })

  const onSubmit: SubmitHandler<{ login: string; accessKey: string }> = (
    formData,
  ) => {
    setIsSubmitting(true)
    // httpClient.post(...)
    navigate('/create-password', {
      state: {
        accessKey: formData.accessKey,
      },
    })

    setIsSubmitting(false)
  }

  return {
    control,
    handleSubmit,
    onSubmit,
    errors,
    t,
    navigate,
    isSubmitting,
  }
}
