import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { decodedPayload } from 'helpers/decodedPayload'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { httpClient } from 'services/api/httpClient'
import { useAuthStore } from 'store/auth.store'
import { SignInType } from 'types/auth/signin'

export function useSignIn() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { login, setUser, setPermissions } = useAuthStore((state) => state)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInType>({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const onSubmit: SubmitHandler<SignInType> = async (formData) => {
    setIsSubmitting(true)
    try {
      const response = await httpClient.post('/auth/login', formData)
      login(response.data.access_token) // salvar token no zustand
      const decoded = decodedPayload(response.data.access_token)
      setPermissions(decoded?.permissions ?? [])

      const { data } = await httpClient.get('/auth/profile') // carregar dados do usuario autenticado
      const user = data
      setUser(user) // salvar dados do usuario no zustand
      navigate('/')
    } catch (error) {
      getErrorMessage(error)
      setIsSubmitting(false)
    }
  }

  return { control, handleSubmit, onSubmit, errors, t, isSubmitting }
}
