import { CssBaseline, LinearProgress } from '@mui/material'
import { LocalizationProvider, ptBR } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import 'moment/locale/pt'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RouterProvider } from 'react-router-dom'
import * as yup from 'yup'

import { yupLocale } from 'helpers/yupLocale'

import { CustomThemeProvider } from './contexts/CustomThemeProvider'
import { SweetAlertProvider } from './contexts/SweetAlertProvider'
import { routers } from './routers/index.router'

import 'react-toastify/dist/ReactToastify.css'
import 'reactflow/dist/style.css'
import './styles/global.css'

yup.setLocale(yupLocale)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1 * (60 * 1000), // 1 mins
      cacheTime: 2 * (60 * 1000), // 2 mins
      refetchOnWindowFocus: true,
    },
  },
})

function App() {
  const { i18n } = useTranslation('common')
  moment.locale(i18n.language)

  return (
    <Suspense fallback={<LinearProgress />}>
      <CustomThemeProvider>
        <CssBaseline />
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={i18n.language}
          localeText={
            ptBR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <SweetAlertProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={routers} />

              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </SweetAlertProvider>
        </LocalizationProvider>
      </CustomThemeProvider>
    </Suspense>
  )
}

export { App }
