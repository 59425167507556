import { Stack, SxProps } from '@mui/material'
import { Link } from 'react-router-dom'

import { Logo } from 'components/Logo'

import { SidebarMenu } from './SidebarMenu'

export function Sidebar(sx?: SxProps) {
  return (
    <>
      <Stack
        direction={'column'}
        spacing={3}
        padding={3}
        sx={{
          width: 300,
          height: '100vh',
          borderRight: (theme) => `2px solid ${theme.palette.secondary.light}`,
          position: 'fixed',
          top: 0,
          left: 0,

          ...sx,
        }}
      >
        <Link to='/'>
          <Logo mode='HORIZONTAL' />
        </Link>

        <Stack
          direction={'column'}
          spacing={2}
          sx={{
            paddingRight: 1,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 6,
              height: 6,
              background: 'transparent',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: (theme) => theme.palette.background.default,
            },
          }}
        >
          <SidebarMenu />
        </Stack>
      </Stack>
    </>
  )
}
