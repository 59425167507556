export interface MenuProps {
  name: string
  description: string
  route: string
  submenu?: MenuProps[]
  image?: string
  module?: string
  hiddenMenu?: boolean
  hiddenBreadcrumbs?: boolean
  isAdmin?: boolean
}

export const mainMenu: MenuProps[] = [
  {
    name: 'Home',
    description: 'dashboardDescription',
    route: '/dashboard',
  },
  {
    name: 'Arquivos',
    description: 'filesDescription',
    route: '/arquivos',
    module: 'file',
  },
  {
    name: 'Férias',
    description: 'Controle de férias',
    route: '/ferias',
    module: 'vacation',
    submenu: [
      {
        name: 'Solicitação de Férias',
        description: 'Solicitação de Férias',
        route: '/ferias/solicitar-ferias',
        module: 'vacation',
      },
    ],
  },
  {
    name: 'Mural de Recados',
    description: 'Mural de recados',
    route: '/mural-de-recados',
    // module: 'message-board',
  },
  {
    name: 'Tipos de Documentos',
    description: 'Tipos de Documentos',
    route: '/tipos-de-documentos',
    module: 'document',
    isAdmin: true,
  },
  {
    name: 'Subtipos de Documentos',
    description: 'Subtipos de Documentos',
    route: '/subtipos-de-documentos',
    module: 'document-type',
    isAdmin: true,
  },
  {
    name: 'Polos',
    description: 'Polos',
    route: '/polos',
    module: 'polo',
    isAdmin: true,
  },
  {
    name: 'Forma de Trabalho',
    description: 'Forma de trabalho',
    route: '/forma-de-trabalho',
    module: 'form-of-work',
    isAdmin: true,
  },
  {
    name: 'Regime de Trabalho',
    description: 'Regime de Trabalho',
    route: '/regime-de-trabalho',
    module: 'working-regime',
    isAdmin: true,
  },
  {
    name: 'Perfis',
    description: 'Perfis',
    route: '/perfis',
    module: 'role',
    isAdmin: true,
  },
  {
    name: 'Colaboradores',
    description: 'Usuários',
    route: '/colaboradores',
    module: 'user',
    isAdmin: true,
    submenu: [
      {
        name: 'Novo Colaborador',
        description: 'Usuários',
        route: '/colaboradores/formulario/',
        isAdmin: true,
        module: 'user',
      },
      {
        name: 'Novo Colaborador',
        description: 'Usuários',
        route: '/colaboradores/formulario',
        isAdmin: true,
        module: 'user',
      },
      {
        name: 'Informações do Colaborador',
        description: 'Usuários',
        route: '/colaboradores/info',
        isAdmin: true,
        module: 'user',
      },
      {
        name: 'Informações do Colaborador',
        description: 'Usuários',
        route: '/colaboradores/info/',
        isAdmin: true,
        module: 'user',
      },
    ],
  },
]
