import { Box, Stack, Typography } from '@mui/material'

import unauthorizedImg from 'assets/images/unauthorized.svg'

export function GardPage() {
  return (
    <Stack
      id='unauthorized-page'
      sx={{
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Stack
        flex={1}
        spacing={6}
        sx={{
          width: '100%',
          alignItems: 'center',
          paddingTop: 6,
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            minHeight: [350, 500],
            minWidth: ['100%', 450],
            background: `url(${unauthorizedImg})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />

        <Box textAlign='center' fontStyle='italic'>
          <Typography variant='body2'>Acesso não autorizado</Typography>
          <span>Você não tem permissão para acessar esta página</span>
        </Box>
      </Stack>
    </Stack>
  )
}
