import { createContext, ReactNode } from 'react'
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2'

import { themeDark, themeLight } from 'styles/themes/muiTheme'

const themes = {
  light: themeLight,
  dark: themeDark,
}

interface SweetAlertProps extends SweetAlertOptions {
  title?: string
  text?: string
  icon?: SweetAlertIcon
  onSuccess?: () => void
}

interface SweetAlertContextType {
  sweetAlert: (props?: SweetAlertProps) => void
}

const SweetAlertContext = createContext<SweetAlertContextType>(
  {} as SweetAlertContextType,
)

interface SweetAlertProviderProps {
  children: ReactNode
}

function SweetAlertProvider({ children }: SweetAlertProviderProps) {
  const themeState = localStorage.getItem('@Atende Portaria:theme-state-1.0.0')
  const { mode } = JSON.parse(themeState ?? '{"mode":"dark"}') as {
    mode: 'light' | 'dark'
  }
  const theme = themes[mode ?? 'dark']

  const sweetAlert = (props?: SweetAlertProps) => {
    Swal.fire({
      title: props?.title ?? 'Deseja excluir?',
      text: props?.text ?? 'Você não terá como retomar esta ação',
      icon: props?.icon ?? 'info',
      showCancelButton: true,
      confirmButtonText: props?.confirmButtonText ?? 'Sim',
      cancelButtonText: props?.cancelButtonText ?? 'Não',
      reverseButtons: true,
      background: theme.palette.background.paper,
      color: theme.palette.getContrastText(theme.palette.background.default),
      confirmButtonColor: theme.palette.primary.main,
      cancelButtonColor: theme.palette.secondary.main,
    }).then((result) => {
      if (result.isConfirmed) {
        props?.onSuccess?.()
        // Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
      }
    })
  }

  const value = {
    sweetAlert,
  }

  return (
    <SweetAlertContext.Provider value={value}>
      {children}
    </SweetAlertContext.Provider>
  )
}

export { SweetAlertContext, SweetAlertProvider }
