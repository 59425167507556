import { Box, Stack, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { useAuthStore } from 'store/auth.store'

import { MenuProps } from '../../configs/menuConfig'

interface Props {
  options: MenuProps[]
}

export function Menu({ options }: Props) {
  const { user, permissions } = useAuthStore((state) => state)
  if (!options.length) return <></>

  return (
    <>
      <Stack direction={'column'} spacing={2}>
        {options
          ?.filter(
            (item) =>
              !item?.hiddenMenu &&
              (!item?.isAdmin ||
                (item?.isAdmin &&
                  user?.role?.slug?.includes('administrador'))) &&
              (!item?.module || permissions.includes(item?.module)),
          )
          .map((item, index) => (
            <Box key={index} sx={{ '& a': { textDecoration: 'none' } }}>
              <NavLink to={item.route} style={{ width: '100%' }}>
                {({ isActive }) => (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    sx={{
                      height: 'auto',
                      minWidth: '100%',
                      width: '100%',
                      borderRadius: '6px',
                      boxShadow: (theme) =>
                        isActive
                          ? `2px 2px 0 ${theme.palette.primary.dark}`
                          : 'none',
                      bgcolor: (theme) =>
                        isActive ? theme.palette.primary.main : 'transparent',
                      transition: 'all 0.5s',
                      '&:hover': {
                        bgcolor: (theme) => theme.palette.primary.dark,
                      },
                    }}
                  >
                    <Stack
                      justifyContent='flex-start'
                      sx={{
                        padding: [2, 3],
                        bgcolor: (theme) => theme.palette.secondary.light,
                        borderBottomRightRadius: '6px',
                        borderBottomLeftRadius: '6px',
                      }}
                    >
                      <Typography color='secondary.contrastText'>
                        {item.name}
                      </Typography>
                    </Stack>
                  </Box>
                )}
              </NavLink>
            </Box>
          ))}
      </Stack>
    </>
  )
}
