/* eslint-disable no-console */
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { Outlet } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Socket, io } from 'socket.io-client'

import { Container } from 'components/Container'
import { ENVIRONMENT } from 'constants/environment'
import { FileType } from 'pages/file/types/file'
import { useAuthStore } from 'store/auth.store'

import { Header } from './components/Header'
import { Sidebar } from './components/Sidebar'

export function DefaultLayout() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const { user } = useAuthStore((state) => state)
  const queryClient = useQueryClient()

  const socketIORef = useRef<Socket | null>(null)

  useEffect(() => {
    socketIORef.current = io(ENVIRONMENT.SOCKET_URL)

    socketIORef.current.on('connect', () => {
      console.log('Conexão estabelecida.')

      socketIORef.current?.emit('start', { userId: user?.id })
    })

    socketIORef.current.on('disconnect', () => {
      console.log('Conexão fechada.')
    })

    socketIORef.current.on('receive-message', (data) => {
      console.log('receive-message', data)
    })

    socketIORef.current.on('receive-file', (data: { message: FileType }) => {
      queryClient.invalidateQueries('files')
      const message = `Um novo documento "${data?.message?.document?.name}" foi adicionado pra você. Nome do arquivo: ${data?.message?.name}.`
      toast.info(message, {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })

      // if (window?.Notification)
      //   new window.Notification('Novo documento', {
      //     body: message,
      //     icon: '/favicon.ico',
      //   })
    })

    socketIORef.current.on('remove-file', (data: { message: string }) => {
      queryClient.invalidateQueries('files')
      toast.info(data.message, {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })

      // if (window?.Notification)
      //   new window.Notification('Documento removido', {
      //     body: data.message,
      //     icon: '/favicon.ico',
      //   })
    })

    return () => {
      socketIORef.current?.disconnect()
    }
  }, [])
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          minHeight: '100vh',
          width: '100%',
          gridTemplateAreas: [
            '"header header header""main main main"',
            '"sidebar header header header""sidebar main main main"',
          ],
          gridTemplateColumns: ['1fr', '300px 1fr'],
          gridTemplateRows: 'auto 1fr',
        }}
      >
        {isDesktop && (
          <Sidebar
            sx={{
              display: ['none', 'flex'],
              gridArea: 'sidebar',
            }}
          />
        )}

        <Header
          sx={{
            gridArea: 'header',
          }}
        />

        <Box
          component='main'
          sx={{
            gridArea: 'main',
          }}
        >
          <Container>
            <Stack spacing={3} paddingBottom={3}>
              <Outlet />
            </Stack>
          </Container>
        </Box>
      </Box>
    </>
  )
}
